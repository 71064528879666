import * as Sentry from '@sentry/react';
import { useCallback, useEffect, useState } from 'react';
import { Paperclip } from 'react-feather';
import { useSelector, useDispatch } from 'react-redux';
import api from '@services/index';
import { proposalsSelector, proposalsStatusSelector } from '@features/Proposals/selectors';
import { loadProposalsAsync } from '@features/Proposals/actions';
import { Badge, ExternalMenuItem, ItemText, MenuItem } from './Common';
import SkeletonMenuItem from './SkeletonMenuItem';

interface Props {
    onItemSelected: () => void;
}

function ProposalMenuItem({ onItemSelected }: Props) {
    const dispatch = useDispatch();
    const [isFetchingProposalURL, setIsFetchingProposalURL] = useState(false);
    const [proposalURL, setProposalURL] = useState('');
    const proposals = useSelector(proposalsSelector);
    const proposalsStatus = useSelector(proposalsStatusSelector);

    const getProposalUrl = useCallback(async () => {
        setIsFetchingProposalURL(true);

        try {
            if (proposals.length !== 1) throw new Error('Multiple proposals even though we should only have one...');

            const proposalUrl = await api.proposalsApi.proposalsControllerGetProposalUrl(proposals[0].id);
            setProposalURL(proposalUrl.data.url);
        } catch (err) {
            Sentry.captureException(err);
        } finally {
            setIsFetchingProposalURL(false);
        }
    }, [proposals]);

    useEffect(() => {
        if (proposals.length !== 0) {
            getProposalUrl();
        }
    }, [proposals, getProposalUrl]);

    useEffect(() => {
        if (proposalsStatus === 'idle') {
            dispatch(loadProposalsAsync.request());
        }
    }, [dispatch, proposalsStatus]);

    const isLoading = proposalsStatus === 'idle' || proposalsStatus === 'loading';

    if (isLoading) {
        return <SkeletonMenuItem width="6.0rem" />;
    }

    if (proposals.length === 0) {
        return null;
    }

    return (
        <>
            {proposals.length === 1 && (
                <>
                    {isFetchingProposalURL && <SkeletonMenuItem width="6.0rem" />}
                    {!isFetchingProposalURL && (
                        <ExternalMenuItem href={proposalURL} target="_blank" rel="noreferrer">
                            <Paperclip />
                            <ItemText>Proposal</ItemText>
                        </ExternalMenuItem>
                    )}
                </>
            )}
            {proposals.length > 1 && (
                <MenuItem to="/proposals" onClick={() => onItemSelected()}>
                    <>
                        <Paperclip />
                        <ItemText>Proposals</ItemText>
                        <Badge>{proposals.length}</Badge>
                    </>
                </MenuItem>
            )}
        </>
    );
}

export default ProposalMenuItem;
