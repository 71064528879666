import LinkCard from '@components/LinkCard';

function LGThinQCard() {
    const userAgent = navigator.userAgent || navigator.vendor;
    let buttonUrl = 'https://www.lg.com/us/lg-thinq/app';

    if (/android/i.test(userAgent)) {
        buttonUrl = 'https://play.google.com/store/apps/details?id=com.lgeha.nuts&hl=en_US&pli=1';
    } else if (/iPad|iPhone|iPod/.test(userAgent)) {
        buttonUrl = 'https://apps.apple.com/us/app/lg-thinq/id993504342';
    }

    return (
        <LinkCard size="md" href={buttonUrl} title="Manage Energy" navigateText="Download LG ThinQ App">
            <p>Sign in to the LG ThinQ app to manage your stored energy preferences.</p>
        </LinkCard>
    );
}

export default LGThinQCard;
